import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SigninConfirmRequest, SigninRequest } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { OverlayService } from '@lightning/wild-ui';
import { finalize } from 'rxjs';

@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss']
})
export class SigninComponent {

    public signinRequest = new SigninRequest();

    public signinConfirmRequest = new SigninConfirmRequest();

    public passwordConfirm = '';

    public isLoading = false;

    constructor(
        private overlayService: OverlayService,
        private onlineService: OnlineService,
        private translateService: TranslateService) {
    }

    public get isSigninInvalid(): boolean {

        return  !this.signinRequest.email ||
                !this.signinRequest.password ||
                this.signinRequest.password != this.passwordConfirm ||
                !this.signinRequest.name;
    }

    public signin(): void {

        this.isLoading = true;

        this.onlineService.signin(this.signinRequest)
            .pipe(finalize(() => {
                this.isLoading = false;
            }))
            .subscribe({
                next: () => {

                    this.overlayService.openNotification({
                        message: this.translateService.instant('accounts.signin.emailSent'),
                        type: 'success',
                        duration: 10000
                    });

                    this.signinConfirmRequest.email = this.signinRequest.email;

                    // this.overlayService.lockCurrentModal();
                }
            });
    }


    public get isSigninConfirmInvalid(): boolean {

        return !this.signinConfirmRequest.code;
    }


    public get x() {

        return '/^hello$/';
    }

    public signinConfirm(): void {

        this.isLoading = true;

        this.onlineService.signinConfirm(this.signinConfirmRequest)
            .pipe(finalize(() => {
                this.isLoading = false;
            }))
            .subscribe({
                next: (account) => {

                    if (account) {

                        this.overlayService.openNotification({
                            message: this.translateService.instant('accounts.confirmation.success'),
                            type: 'success'
                        });

                        this.overlayService.closeModal();
                    }
                }
            });
    }



    // To make the development easier
    public randomFill(): void {

        this.signinRequest = {
            name: 'test',
            email: Math.round(Math.random() * 1000) + 'test@gmail.com',
            password: 'pass'
        };

        this.passwordConfirm = this.signinRequest.password;
    }

}
