
<video class="backgroundVideo"
    loop muted autoplay playsinline
    oncanplay="this.play()"
    onloadedmetadata="this.muted = true">
    <source [src]="video" type="video/mp4">
</video>

<div class="fading"
    [class.fadingBottom]="(image || video) && isFirstElement"
    [class.fadingTopAndBottom]="(image || video) && !isFirstElement">
</div>

<div class="overlay">
    <div
        wuiTacticalCorners
        [wuiTacticalCornersOptions]="wuiTacticalCornersOptions"
        [class]="['wrapper', size, align]">

        <div
            *ngIf="logo && align !== 'center'"
            class="logo side"
            [class.negative]="negativeLogo"
            [wuiBackgroundImage]="logo">
        </div>

        <div class="content">

            <div
                *ngIf="logo && align === 'center'"
                class="logo top"
                [wuiBackgroundImage]="logo">
            </div>

            <div class="title">
                <ng-content select="[title]"></ng-content>
            </div>

            <div class="body">
                <ng-content select="[body]"></ng-content>
            </div>

            <div class="details">
                <ng-content select="[details]"></ng-content>
            </div>

            <video class="foregroundVideo" *ngIf="video" loop muted autoplay playsinline oncanplay="this.play()" onloadedmetadata="this.muted = true">
                <source [src]="video" type="video/mp4">
            </video>

        </div>
    </div>

</div>

