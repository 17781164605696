<div class="line">

    <div class="social">
        <a [href]="socials.discord" target="_blank">DISCORD</a>
        <a [href]="socials.instagram" target="_blank">INSTAGRAM-CLOSED-DEV-LOG</a>
        <a [href]="socials.facebook" target="_blank" wuiDevelopmentOnly>FACEBOOK</a>
        <a [href]="'mailto:' + socials.mail">EMAIL</a>
    </div>

    <div class="logos optional">
        <a class="mw" href=""></a>
        <a class="lightning" href=""></a>
    </div>

</div>

<div class="copyrights">
    {{ 'footer.copyrights' | translate: { year: year, hostName: host ? host.name : '???' } }}

    @if(cicd.tag || cicd.pipeline) {
        : #{{ cicd.tag || cicd.pipeline }}
    }

</div>

<div class="line" wuiDevelopmentOnly>
    <div class="links">
        <a href="">{{ 'footer.termsOfService' | translate }}</a>
        <a href="">{{ 'footer.privacyPolicy' | translate }}</a>
        <a (click)="learnMoreCookies()">{{ 'footer.cookiesLearnMore' | translate }}</a>
    </div>
</div>
