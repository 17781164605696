import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { TacticalCornersOptions } from '@lightning/wild-ui';

@Component({
    selector: 'app-stack',
    templateUrl: './stack.component.html',
    styleUrls: ['./stack.component.scss']
})
export class StackComponent implements OnInit {

    @Input()
    public wuiTacticalCornersOptions: TacticalCornersOptions = { disappear: false };

    @Input()
    public size: 'minimal' | 'small' | 'medium' | 'large' | 'largeAlways' = 'medium';

    @Input()
    public align: 'left' | 'center' | 'right' | 'wide' = 'left';

    @Input()
    public logo = '';

    @Input()
    public negativeLogo = false;

    @Input()
    public image = '';

    @Input()
    public video = '';

    @HostBinding('style.backgroundImage')
    private backgroundImage = '';

    public isFirstElement = false;

    constructor(private elementRef: ElementRef) {}

    ngOnInit(): void {

        this.backgroundImage = 'url(' + this.image + ')';

        this.isFirstElement = this.elementRef.nativeElement.parentNode.children[0] === this.elementRef.nativeElement;
    }

}
