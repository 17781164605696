<div class="overlay" >
    <div class="wrapper" wuiTacticalCorners [wuiTacticalCornersOptions]="{ disappear: false }">
        <div class="title">
            <ng-content select="[title]"></ng-content>
        </div>
        <div class="subtitle">
            <ng-content select="[subtitle]"></ng-content>
        </div>
    </div>
</div>
