import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map, of, switchMap } from 'rxjs';

import { Account, AccountJWTPayload, Address } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { OverlayService } from '@lightning/wild-ui';

import { ModalAddressComponent } from '../../../shared/components/modal-address/modal-address.component';
import { ModalYesNoComponent } from '../../..//shared/components/modal-yes-no/modal-yes-no.component';
import { LoginComponent } from '../../components/login/login.component';

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    public account = new Account();

    constructor(
        private onlineService: OnlineService,
        private router: Router,
        private overlayService: OverlayService) {

        this.loadAccount().subscribe();
    }

    public get isLoggedIn(): boolean {

        return this.onlineService.isTokenValid();
    }

    public get tokenPayload(): AccountJWTPayload | undefined {

        return this.onlineService.tokenPayload;
    }

    public login(): void {

        this.overlayService.openModal({
            component: LoginComponent
        });
    }

    public loadAccount(): Observable<Account> {

        if (!this.onlineService.tokenPayload?.id) {

            this.account = new Account();

            return of(this.account);
        }

        return this.onlineService.getAccount(this.onlineService.tokenPayload?.id)
            .pipe(
                switchMap((account) => {

                    this.account = account;

                    // Refresh the jwt token on the fly if it appears outdated
                    if (account.operatorId !== this.onlineService.tokenPayload?.operatorId) {
                        return this.onlineService.getFreshToken().pipe(map(() => account));
                    }

                    return of(this.account);
                })
            );
    }

    public async addAddress(): Promise<void> {

        const address = await this.overlayService.openModal({
            component: ModalAddressComponent,
            inputs: {
                title: 'accounts.addresses.add',
                address: new Address()
            }
        });

        if (!address) {
            return;
        }

        this.onlineService.addAccountAddress(this.onlineService.tokenPayload?.id, address).subscribe({
            next: (addresses) => {
                this.account.addresses = addresses;
            }
        });
    }

    public async editAddress(address: Address): Promise<void> {

        address = await this.overlayService.openModal({
            component: ModalAddressComponent,
            inputs: {
                title: 'accounts.addresses.edit',
                address
            }
        });

        if (!address) {
            return;
        }

        this.onlineService.updateAccountAddress(this.onlineService.tokenPayload?.id, address).subscribe({
            next: (addresses) => {
                this.account.addresses = addresses;
            }
        });
    }

    public async removeAddress(address: Address): Promise<void> {

        const confirm = await this.overlayService.openModal({
            component: ModalYesNoComponent,
            inputs: {
                title: 'accounts.addresses.remove.title',
                message: 'accounts.addresses.remove.confirm'
            }
        });

        if (!confirm) {
            return;
        }

        this.onlineService.deleteAccountAddress(this.onlineService.tokenPayload?.id, address.id).subscribe({
            next: (addresses) => {
                this.account.addresses = addresses;
            }
        });
    }

    public async logout(): Promise<void> {

        // const confirm = await this.overlayService.openModal({
        //     component: ModalYesNoComponent,
        //     inputs: {
        //         title: 'accounts.logout.title',
        //         message: 'accounts.logout.confirm'
        //     }
        // });

        // if (!confirm) {
        //     return;
        // }

        this.onlineService.logout();

        this.router.navigate(['']);
    }


}
