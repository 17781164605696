<div class="bar" [class.minified]="scrollService.isScrolled">

    <a routerLink="/" class="product"></a>

    <div class="title"></div>

    <div class="store cart" routerLink="/store/cart" wuiDevelopmentOnly>
        <div class="icon"></div>
        @if(storeService.cartItemsCount()) {
            <div class="count">
                x {{ storeService.cartItemsCount() }}
            </div>
        }
    </div>

    <div
        class="logged"
        [class]="accountService.isLoggedIn ? 'in' : 'out'"
        [title]="accountService.tokenPayload?.email || ''"
        (click)="openAccount()">
    </div>

    <div
        class="menu"
        (click)="openMenu()"
        wuiDevelopmentOnly>
    </div>
</div>
