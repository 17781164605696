<wui-modal-layout>

    <div title (click)="randomFill()">{{ 'shared.signin' | translate }}</div>

    <div body>

        <wui-section [wuiDisabled]="isLoading">

            @if(!signinConfirmRequest.email) {

                <wui-entry>

                    <div name>
                        {{ 'shared.firstName' | translate | capitalize }}
                    </div>

                    <wui-input
                        value
                        type="text"
                        [(value)]="signinRequest.name"
                        autocomplete="given-name"
                        [required]="true">
                    </wui-input>

                </wui-entry>

                <wui-entry>

                    <div name>
                        {{ 'shared.email' | translate | capitalize }}
                    </div>

                    <wui-input
                        type="email"
                        [(value)]="signinRequest.email"
                        autocomplete="email"
                        [required]="true">
                    </wui-input>

                </wui-entry>

                <wui-entry>

                    <div name>
                        {{ 'shared.password' | translate | capitalize }}
                    </div>

                    <wui-input
                        type="password"
                        [(value)]="signinRequest.password"
                        [autocomplete]="'off'"
                        [min]="8">
                    </wui-input>

                    <div detail>
                        {{ 'shared.passwordFormat' | translate: { minimalLength: 8 } | capitalize }}
                    </div>

                </wui-entry>

                <wui-entry>

                    <div name>
                        {{ 'shared.passwordConfirm' | translate | capitalize }}
                    </div>

                    <wui-input
                        type="password"
                        [(value)]="passwordConfirm"
                        [autocomplete]="'off'"
                        [pattern]="'^' + signinRequest.password + '$'">
                    </wui-input>

                </wui-entry>

            } @else {

                <wui-input
                    type="text"
                    [(value)]="signinConfirmRequest.code"
                    [placeholder]="'shared.code' | translate"
                    pattern="^[A-Z0-9]{8}$">
                </wui-input>

            }

        </wui-section>

    </div>

    <div footer>
        <wui-buttons>

            @if(!signinConfirmRequest.email) {
                <wui-button (click)="signin()" [wuiDisabled]="isSigninInvalid">{{ 'shared.signin' | translate }}</wui-button>
            } @else {
                <wui-button (click)="signinConfirm()" [wuiDisabled]="isSigninConfirmInvalid">{{ 'shared.confirm' | translate }}</wui-button>
            }
        </wui-buttons>
    </div>

</wui-modal-layout>
